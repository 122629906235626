<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-sack-percent</v-icon> Análisis de rendimientos de productores</h1><br>
        <v-layout wrap>
          <v-flex xs2>
            <v-menu
              v-model="menuDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  autocomplete="off"
                  v-model="dateFormatted"
                  label="Fecha de análisis"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                @change="changeDateFormatted('fecha');load();"
                locale="es"
                :first-day-of-week="1"
                v-model="dateInput"
                outlined
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Albaran"
              v-model="albaran"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Kilos"
              v-model="kilos"
              type="number"
              min="0"
              step="0.5"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Rendimiento bruto"
              v-model="bruto"
              type="number"
              min="0"
              step="0.5"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              label="Rendimiento neto"
              v-model="neto"
              type="number"
              min="0"
              step="0.5"
              outlined
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" @click="save"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex xs12>
        <v-data-table
          :headers="headersResumen"
          :items="resumen"
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="elimina(item.id)">
            mdi-delete
          </v-icon>
        </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
export default {
  name: 'Rendimientos',
  data () {
    return {
      albaran: '',
      kilos: '',
      bruto: '',
      neto: '',
      menuDate: '',
      dateFormatted: '',
      dateInput: '',
      headersResumen: [
        {
          text: 'Fecha análisis',
          align: 'start',
          sortable: true,
          value: 'fecha',
        },
        { text: 'Nº albarán', value: 'albaran' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Rendimiento bruto', value: 'rendimientobruto' },
        { text: 'Rendimiento neto', value: 'rendimientoneto' },
        { text: 'Estado', value: 'estado' },
        { text: "", value: "actions" },
      ],
      resumen: [],
    }
  },
  components: {
    showMessage,
    showDialog,
  },
  mounted(){
    this.dateInput = basics.getDate();
    this.dateFormatted = basics.formatDate(this.dateInput,"dd/mm/yyyy");
    this.read();
  },
  methods:{
    changeDateFormatted(field){
      switch(field){
        case "fechal":
          if(this.dateInput){
            this.dateFormatted = basics.formatDate(this.dateInput,"dd/mm/yyyy");
          }
          break;
      }
    },
    clear(){
      this.albaran = '';
      this.kilos = '';
      this.bruto = '';
      this.neto = '';
      this.menuDate = '';
      this.dateInput = basics.getDate();
      this.dateFormatted = basics.formatDate(this.dateInput,"dd/mm/yyyy");
    },
    async save(){
      if(this.dateInput!='' && this.neto!='' && this.bruto!='' && this.kilos!='' && this.albaran!=''){
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+this.$store.state.auth
        };
       const data = {
          fecha: this.dateInput,
          albaran: this.albaran,
          kilos: this.kilos,
          rendimientoNeto: this.neto,
          rendimientoBruto: this.bruto,
          estado: 'espera'
        };
        const response = await fetch(
          `${BASE_URL}/analisisRendimiento`,
          {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data),
          }
        );
        switch (response.status) {
          case 200:
            this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
            this.clear();
            this.read();
            break;
          default:
            this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados. No se ha podido crear la petición.", "Cerrar", "red");
            break;
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. Debe de completar todos los campos antes de hacer el envío", "Cerrar", "red");
      }
    },
    async read(){
      this.resumen = [];
      const response = await genericReload(this.$store.state.auth, `analisisRendimiento`);
      if (response.status === 200) {
        const json = await response.json();
        let estado;
        for(let i = 0; i<json.body.length; i++){
          if(json.body[i].estado == "aceptado") { estado = "Aceptado"; }
          if(json.body[i].estado == "rechazado") { estado = "Rechazado"; }
          if(json.body[i].estado == "espera") { estado = "En espera"; }
          this.resumen.push({
            id: json.body[i].id, 
            fecha: basics.formatDate(json.body[i].fecha,"dd/mm/yyyy"), 
            albaran: json.body[i].albaran,
            kilos: json.body[i].kilos,
            estado: estado,
            rendimientobruto: json.body[i].rendimientoBruto,
            rendimientoneto: json.body[i].rendimientoNeto,
          });
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de registros anteriores", "Cerrar", "red");
      }
    },
    elimina(id){
      this.$refs.dialog.setNew("Eliminar análisis de rendimiento", "¿Estás seguro de querer llevar a cabo esta operación?", 0).then(async(confirm) => {
        if(confirm.response){
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+this.$store.state.auth
          };
          const response = await fetch(
            `${BASE_URL}/analisisRendimiento/${id}`,
            {
              method: 'DELETE',
              headers: headers,
            }
          );
          switch (response.status) {
            case 200:
              this.$refs.snackbar.setNew(true, "Registro eliminado correctamente", "Ok", "green");
              this.read();
              break;
            default:
              this.$refs.snackbar.setNew(true, "ERROR. El registro no ha podido ser eliminado", "Cerrar", "red");
              break;
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
</style>